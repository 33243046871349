import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import Layout from "../components/layout"
import Seo from "../components/seo"

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class TermsPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')   
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = "Terms and Conditions"
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName +" "+ siteTitle}  keywords={keywords}/>
        <Layout data={this.props} pagewidth={pagewidth.matches}>        
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <h1 style={{textAlign: "start"}}>Terms and Conditions</h1>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Overview</h3>
                    <div className="legalscontent">
                      This website is operated by EdgeForecaster Technologies. Throughout the site, the terms “EdgeForecaster”, “company”, “we”, “us” and “our” refer to EdgeForecaster Technologies. EdgeForecaster Technologies offers this website, including all information, tools and services available from this site to the user, conditioned upon the user’s acceptance of all terms, conditions, policies and notices stated here.
                      By visiting and/ or purchasing something from the EdgeForecaster site, the user engages in EdgeForecaster’s “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.  
                      Please read these Terms of Service carefully before accessing or using the EdgeForecaster website. By accessing or using any part of the site, the user agrees to be bound by these Terms of Service. If the user does not agree to all the terms and conditions of this agreement, then the user may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service. 
                      Any new features or tools, which are added to, the current site shall also be subject to the Terms of Service. A user can review the most current version of the Terms of Service at any time on this page. EdgeForecaster reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to the EdgeForecaster website. It is a user’s responsibility to check this page periodically for changes. A user’s continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 1 - Online Store Terms</h3>
                    <div className="legalscontent">
                      By agreeing to these Terms of Service, a user represents that they are at least the age of majority in their state, province or country of residence, or that the user are the age of majority in their state, province or country of residence and they have given EdgeForecaster their consent to allow any of the user’s minor dependents to use this site.
                      A user may not use our products for any illegal or unauthorised purpose nor may a user, in the use of the Service, violate any laws in their jurisdiction (including but not limited to copyright laws).
                      A user must not transmit any malicious software, communications or any code of a destructive nature.
                      A breach or violation of any of the Terms will result in an immediate termination of a user’s access to Services.
                    </div>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 2 - General Conditions</h3>
                    <div className="legalscontent">
                      EdgeForecaster reserve the right to refuse service to any user for any reason at any time.
                      Users understand that their data (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                      Users agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by EdgeForecaster.
                      The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 3 - Accuracy, Completeness and Timeliness of Information</h3>
                    <div className="legalscontent">
                      The data on this site is provided by EdgeForecaster Technologies and it is not to be construed as an offer or solicitation for the purchase or sale of any financial instrument or the provision of an offer to provide investment services. Information, opinions and comments contained on this site are not under the scope of investment advisory services. Investment advisory services are given according to the investment advisory contract, signed between the intermediary institutions, portfolio management companies, investment banks and the clients. Forecasts, predictions, visualisations and data delivered through the EdgeForecaster Service or listed on the EdgeForecaster website are created through artificial intelligence and machine learning models. Where all care, expertise and attention is paid to ensure the accuracy of these models, financial instruments and markets can be erratic and volatile environments. As such, forecasts, predictions, visualisations and data delivered through the Service or listed on the EdgeForecaster website should be utilised as a tool and not construed as financial advice. Similarly, opinions, views and comments contained in this site reflect the views of the individual who supplied them. The investments discussed or recommended through the EdgeForecaster website or subsequent community may involve significant risk, may be illiquid and may not be suitable for all investors. Therefore, making decisions with respect to the information delivered through the EdgeForecaster website, the Service or subsequent community may cause unpredictable results.
                      EdgeForecaster Technologies is not responsible if information made available on this website or through the Service is not accurate, complete or current. The material on this website and delivered through the Service has been prepared for informational purposes only and intended to be a tool in investment decisions. Any reliance on the material on this site is at the user’s own risk.
                      This site may contain certain historical information. Historical information, necessarily, is not current and is provided for a user’s reference only. EdgeForecaster reserve the right to modify the contents of this site at any time, but have no obligation to update any information on our site. A user agrees that it is their responsibility to monitor changes to the EdgeForecaster site.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 4 - Modifications to the Service and Prices</h3>
                    <div className="legalscontent">
                    Prices for EdgeForecaster products are subject to change without notice.
                    EdgeForecaster reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                    EdgeForecaster shall not be liable to a user or to any third-party for any modification, price change, suspension or discontinuance of the Service.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 5 - Products or Services</h3>
                    <div className="legalscontent">
                      Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to Edgeforecaster’s Return Policy.
                      EdgeForecaster have made every effort to display as accurately as possible the data, layouts, colors and images within the EdgeForecaster products and store. EdgeForecaster cannot guarantee that your computer or device’s display of any data, layouts, colors or images will be accurate.
                      EdgeForecaster reserve the right, but are not obligated, to limit the sales of EdgeForecaster products or Services to any person, geographic region or jurisdiction. EdgeForecaster may exercise this right on a case-by-case basis. EdgeForecaster reserve the right to limit the quantities of any products or services offered. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of EdgeForecaster. EdgeForecaster reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                      EdgeForecaster do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 6 - Accuracy of Billing and Account Information</h3>
                    <div className="legalscontent">
                      EdgeForecaster reserve the right to refuse any order a user places through the EdgeForecaster store. EdgeForecaster may, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that EdgeForecaster make a change to or cancel an order, EdgeForecaster may attempt to notify the user by contacting the e-mail and/or billing address/phone number provided at the time the order was made. EdgeForecaster reserve the right to limit or prohibit orders that appear to be placed by dealers, resellers or distributors.
                      The user agrees to provide current, complete and accurate purchase and account information for all purchases made at the EdgeForecaster store. The user agrees to promptly update their account and other information, including email address and credit card numbers and expiration dates, so that EdgeForecaster can complete transactions and contact the user as needed.
                      There will be no refund/chargeback to the customer after completion of orders or use of services.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 7 - Third Party Tools</h3>
                    <div className="legalscontent">
                      EdgeForecaster may provide a user with access to third-party tools over which EdgeForecaster neither monitor nor have any neither control nor input.
                      The user acknowledges and agrees that EdgeForecaster provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. EdgeForecaster shall have no liability whatsoever arising from or relating to a user’s use of third-party tools.
                      Any use by a user of third party tools offered through the site is entirely at their own risk and a user should ensure that they are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                      EdgeForecaster may also, in the future, offer new services and/or features through the website or Service (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 8 - Third Party Links and Data</h3>
                    <div className="legalscontent">
                      Certain content, products and services available via our Service may include materials or data from third-parties.
                      Data and information on this website and delivered through the Service are sourced from third-parties not affiliated with EdgeForecaster. EdgeForecaster are not responsible for the accuracy or validity of the data nor are EdgeForecaster responsible for ensuring the data is up to date. EdgeForecaster will not have any liability or responsibility for third-party data.
                      Third-party links on this site may direct a user to third-party websites that are not affiliated with EdgeForecaster. EdgeForecaster are not responsible for examining or evaluating the content or accuracy and do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                      EdgeForecaster are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure they are understood before engaging in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 9 - User Comments, Feedback and other Submissions</h3>
                    <div className="legalscontent">
                      If, at EdgeForecaster’s request, a user sends certain specific submissions (for example contest entries) or without a request from EdgeForecaster, a user submits creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), the user agrees that EdgeForecaster may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that are submitted to EdgeForecaster. EdgeForecaster are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
                      EdgeForecaster may, but have no obligation to, monitor, edit or remove content that the company determine, in their sole discretion, are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                      The user agrees that their comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. The user further agrees that their comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. The user may not use a false e-mail address, pretend to be someone other than themself, or otherwise mislead EdgeForecaster or third-parties as to the origin of any comments. The user is solely responsible for any comments they make and their accuracy. EdgeForecaster take no responsibility and assume no liability for any comments posted by a user or any third-party.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 10 - Personal Information</h3>
                    <div className="legalscontent">
                      A user’s submission of personal information through the store is governed by EdgeForecaster Privacy Policy.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 11 - Errors, Inaccuracies and Omissions</h3>
                    <div className="legalscontent">
                      Occasionally there may be information on the EdgeForecaster site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. EdgeForecaster reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after a user has submitted an order).
                      EdgeForecaster undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 12 - Prohibited Uses</h3>
                    <div className="legalscontent">
                      In addition to other prohibitions as set forth in the Terms of Service, users are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate EdgeForecaster’s intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. EdgeForecaster reserve the right to terminate a user’s use of the Service or any related website for violating any of the prohibited uses.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 13 - Disclaimer of Warranties; Limitation of Liability</h3>
                    <div className="legalscontent">
                      EdgeForecaster do not guarantee, represent or warrant that a user’s use of the EdgeForecaster service will be uninterrupted, timely, secure or error-free.
                      EdgeForecaster do not warrant that results that may be obtained from the use of the service will be accurate or reliable.
                      Users agree that from time to time EdgeForecaster may remove the service for indefinite periods of time or cancel the service at any time, without notice to the user.
                      Users expressly agree that their use of, or inability to use, the service is at their sole risk. The service and all products and services delivered to the user through the service are (except as expressly stated by EdgeForecaster) provided 'as is' and 'as available' for use by the user, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                      In no case shall EdgeForecaster Technologies, their directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from a user’s use of any of the service or any products procured using the service, or for any other claim related in any way to the user’s use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, EdgeForecaster’s liability shall be limited to the maximum extent permitted by law.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 14 - Indemnification</h3>
                    <div className="legalscontent">
                      The user agrees to indemnify, defend and hold harmless EdgeForecaster Technologies and their parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of the user’s breach of these Terms of Service or the documents they incorporate by reference, or the user’s violation of any law or the rights of a third-party.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 15 - Severability</h3>
                    <div className="legalscontent">
                      In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 16 - Termination</h3>
                    <div className="legalscontent">
                      The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                      These Terms of Service are effective unless and until terminated by either the user or by EdgeForecaster. A user may terminate these Terms of Service at any time by notifying EdgeForecaster that the user no longer wish to use EdgeForecaster’s Services, or when the user ceases to use the EdgeForecaster website or Service.
                      If in EdgeForecaster sole judgment a user fails, or EdgeForecaster suspect that a user has failed, to comply with any term or provision of these Terms of Service, EdgeForecaster also may terminate this agreement at any time without notice and the user will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny the user access to our Services (or any part thereof).
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 17 - Entire Agreement</h3>
                    <div className="legalscontent">
                      The failure of EdgeForecaster to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                      These Terms of Service and any policies or operating rules posted by EdgeForecaster on this site or in respect to the Service constitutes the entire agreement and understanding between the user and EdgeForecaster and govern the user’s use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between the user and EdgeForecaster (including, but not limited to, any prior versions of the Terms of Service).
                      Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 18 - Changes to Terms of Service</h3>
                    <div className="legalscontent">
                      A user can review the most current version of the Terms of Service at any time at this page.
                      EdgeForecaster reserve the right, at their sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to the EdgeForecaster website. It is a user’s responsibility to check the EdgeForecaster website periodically for changes. A user’s continued use of or access to the EdgeForecaster website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
      
    )}
}

export default withCookies(TermsPage)
export const TermsPageQuery = graphql`
  query TermsPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl              
        }
        keywords
      }
    }
    sitePage {
        pluginCreator {
          name
        }
      }
  }
`